
(function() {
  if(_isRobot()) {
    return;
  }
  /**
   * External link tracking
   */
  var cxExternalLinkTracker = (function(){
    var _cpMaxLen = 40;
    var _eventHandler;
    var _targetArea = {
      "txtarea": "txtarea",
      "entryContent":"entryContent",
      "single":"single"
    };
    function _init(eventHandler) {
        _eventHandler = eventHandler;
        _startTracking();
    }

    function _startTracking() {
      window.addEventListener('mouseup', _onClickEvent, true);
      window.addEventListener('contextmenu', _onClickEvent, true);
    }

    function _stopTracking() {
      window.removeEventListener('mouseup', _onClickEvent, true);
      window.removeEventListener('contextmenu', _onClickEvent, true);
    }

    function _onClickEvent(event) {
      try {
        var ev = event || window.event;
        var href = "";
        var text = "";
        if (ev) {
          var el = ev.target || ev.srcElement;
          for(var i = 0; (i < 10) && (el) && el.nodeName.toLowerCase() !== 'body'; i++) {
            if(el.nodeType === 1 && el.nodeName.toLowerCase() === 'a') {
              if(typeof el.href === 'string') {
                href = el.href;
              }
              if(text.length == 0 && el.innerText.length > 0) {
                text = el.innerText;
              }
            }
            var areaName = _getAreaName(el, el.parentNode ? el.parentNode.childNodes : []);
            if(areaName) {
              break;
            }
            el = el.parentNode;
          }
          if(areaName && href) {
            text = text || href;
            _eventHandler(
              areaName.slice(0, _cpMaxLen),
              {
                "text": text.slice(0, _cpMaxLen),
                "href": href.replace(/^https?:\/\//, '').slice(0, _cpMaxLen)
              }
            );
          }
        }
      } catch (e) { }
      return true;
    }
    function _getAreaName(el, neighborNodes) {
      if(el.className) {
        var classNames = el.className.split(" ");
        for(var i=0; i<classNames.length; i++) {
          if(Object.keys(_targetArea).indexOf(classNames[i])!=-1) {
            return _targetArea[classNames[i]];
          }
        }
      }
    }
    return {
      init: function(eventHandler) {
        _init(eventHandler);
      }
    }
  })();

  function _isRobot() {
    var robot_strings = ["preview", "phantomjs", "spider", "crawl", "cxense", "slurp", "read ahead agent"];
    var re_bot = /\bbot|bot\b/;
    var ua = navigator.userAgent.toLowerCase();
    if(re_bot.test(ua)) {
      return true;
    } else {
      for(var i=0; i<robot_strings.length; i++) {
        if(ua.indexOf(robot_strings[i])!=-1) {
          return true;
        }
      }
    }
    return ua.indexOf("mozilla") == 0 && ua.indexOf(" ") == -1;
  }



  var _cXcustom = function () { (window.cXcustom.q = window.cXcustom.q || []).push(arguments); _queueExecute(); };
  _cXcustom.q = window.cXcustom && window.cXcustom.q ? window.cXcustom.q : [];
  window.cXcustom = _cXcustom;

  _queueExecute();

  function _queueExecute() {
    while (window.cXcustom.q && window.cXcustom.q.length > 0) {
      var command = window.cXcustom.q.shift();
      try {
        if (command[0] === "scrollDepth") {
            initCxScrollDepth(command[1], command[2], command[3], command[4], command[5]);            
        }else if(command[0] === "externalLink") {
          cxExternalLinkTracker.init(command[1]);
        }
      } catch (e) { }
    }
  }
})();
